import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { RichText } from "prismic-reactjs";
import styled from "styled-components";
import { Parallax } from "react-scroll-parallax";
import { useIntl } from "gatsby-plugin-intl";

import Shape from "../../images/shape-2.inline.svg";
import Button from "../Button";
import { addGifToContent } from "../../utils";

const StyledServiceItem = styled.div`
  span {
    color: ${props => props.theme.colors.gray};
  }
  p {
    margin-bottom: 1.5rem;
  }
`;

const ParallaxShape = styled(Parallax)`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 150px;
  top: 25%;
  svg {
    width: 100%;
    height: auto;
  }
`;

const ServiceItem = ({ service, className, ...props }) => {
  const intl = useIntl();
  const serializer = addGifToContent();

  return (
    <StyledServiceItem className={`mb-5 pb-5 ${className}`} {...props}>
      <span className="mb-3 d-block">({service.index + 1}.)</span>
      <h2 className="h5 mb-4">{service.data.title.text}</h2>
      <RichText
        render={service.data.short_description.raw}
        htmlSerializer={serializer}
      />
      <Button to={`/servicios/${service.uid}`}>
        {intl.formatMessage({ id: "view_more" })}
      </Button>
    </StyledServiceItem>
  );
};

const ServicesList = ({ services, image }) => {
  const servicesColumns = services.reduce(
    (accumulator, currentItem, index) => {
      if ((index + 1) % 2 === 0) {
        accumulator.rightCol = [
          ...accumulator.rightCol,
          { ...currentItem, index },
        ];
      } else {
        accumulator.leftCol = [
          ...accumulator.leftCol,
          { ...currentItem, index },
        ];
      }
      return accumulator;
    },
    { leftCol: [], rightCol: [] }
  );

  return (
    <Container className="position-relative pb-lg-5 mb-5">
      <ParallaxShape y={[0, 150]}>
        <Shape />
      </ParallaxShape>
      <Row>
        <Col lg={5}>
          {servicesColumns.leftCol.map(service => (
            <ServiceItem key={service.uid} service={service} />
          ))}
        </Col>
        <Col lg={5} className="ml-auto pt-lg-5">
          {servicesColumns.rightCol.map((service, index) => (
            <ServiceItem
              key={service.uid}
              service={service}
              className={index === 0 ? "mt-lg-5 pt-lg-5" : ""}
            />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default ServicesList;
