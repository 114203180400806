import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { addGifToContent } from "../../utils";

const Title = styled.h2`
  font-size: 1.2rem;
`;

const Wrapper = styled.div`
  a {
    color: #000;
    text-decoration: underline;
  }
`;

const Approach = ({ title, content, image, gifs }) => {
  const img = getImage(image.localFile.childImageSharp);
  const serializer = addGifToContent();

  return (
    <Container className="py-5 my-5">
      <Row>
        <Col lg={6} className="mb-4 mb-lg-0">
          <Title className="my-4">{title}</Title>
          <Wrapper>
            <RichText render={content} htmlSerializer={serializer} />
          </Wrapper>
        </Col>
        <Col lg={5} className="ml-auto">
          <GatsbyImage image={img} alt={image.alt || ""} />
        </Col>
      </Row>
    </Container>
  );
};

export default Approach;
