import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Approach from "../components/services/Approach";
import ServicesList from "../components/services/ServicesList";
import RecentProjects from "../components/home/RecentProjects";

const ServicesPage = ({ data }) => {
  const {
    prismicServicesPage,
    allPrismicServicios,
    allPrismicProyectos,
  } = data;
  const approach = prismicServicesPage.data.approach[0];

  return (
    <Layout>
      <Approach
        title={approach.title1.text}
        content={approach.content.raw}
        image={prismicServicesPage.data.top_image}
        gifs={prismicServicesPage.data.gifs}
      />
      <ServicesList
        services={allPrismicServicios.nodes}
        image={prismicServicesPage.data.bottom_image}
      />
      <RecentProjects projects={allPrismicProyectos.nodes} />
      <div className="pt-5"></div>
    </Layout>
  );
};

export const query = graphql`
  query($language: String!) {
    prismicServicesPage(lang: { eq: $language }) {
      data {
        approach {
          title1 {
            text
          }
          content {
            raw
          }
        }
        top_image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        bottom_image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        gifs {
          gif {
            url
          }
          word {
            text
          }
        }
      }
    }
    allPrismicServicios(filter: { lang: { eq: $language } }) {
      nodes {
        uid
        data {
          title {
            text
          }
          short_description {
            raw
          }
        }
      }
    }
    allPrismicProyectos(filter: { lang: { eq: $language } }, limit: 3) {
      nodes {
        data {
          title {
            text
          }
          cover {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        uid
      }
    }
  }
`;

export default ServicesPage;
